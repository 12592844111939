import { Link, graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import styled from 'styled-components'

const GalleryStyles = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
`;

export default function Projects({ data }) {
    const projects = data.projects.nodes
    return (
        <Layout>
            <SEO title={"All Projects"} />
            <GalleryStyles>
                <p>More work coming soon.</p>
                <div>
                {projects.map(project => (
                    <Link to={"/projects/" + project.frontmatter.slug} key={project.id}>
                        <div>
                            <Img fluid={project.frontmatter.thumb.childImageSharp.fluid} />
                            <p>{project.frontmatter.title}</p>
                        </div>
                    </Link>
                ))}
                </div>
            </GalleryStyles>
        </Layout>
    )
}

export const query = graphql`
query ProjectsPage {
  projects: allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}
                filter: {frontmatter: {published: {eq: true}}})  {
    nodes {
      frontmatter {
        slug
        date
        title
        published
        thumb {
            childImageSharp{
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
      }
      id
    }
  }
}
`;